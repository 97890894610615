import {
  COMMUNITY_USER_TAG_WITHOUT_USER_FRAGMENT,
} from '@/graphql/_Fragments/CommunityUserTag/WithoutUser';

export const ARTICLE_WITH_TAGS_FRAGMENT = `
  fragment articleWithTagsFragment on Article {
    uid
    tags: _myTags(myUid: "%authUser%") {
      ...communityUserTagWithoutUserFragment
    }
  }
  ${COMMUNITY_USER_TAG_WITHOUT_USER_FRAGMENT}
`;
